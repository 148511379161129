import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62c6d0db"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "formarea" }
const _hoisted_2 = { class: "btnbox" }
const _hoisted_3 = {
  key: 1,
  class: "submit-btn",
  style: {"opacity":"0.7"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_picker = _resolveComponent("van-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!
  const _component_van_uploader = _resolveComponent("van-uploader")!
  const _component_van_form = _resolveComponent("van-form")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_dialog = _resolveComponent("van-dialog")!
  const _component_personliable_dialog = _resolveComponent("personliable-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_nav_bar, {
      "left-arrow": "",
      title: _ctx.$route.meta.title,
      placeholder: "",
      onClickLeft: _ctx.fGoBack
    }, null, 8, ["title", "onClickLeft"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_van_form, {
        "show-error-message": false,
        "show-error": true,
        "label-width": "100px",
        ref: "form",
        onSubmit: _ctx.onSubmit
      }, {
        default: _withCtx(() => [
          _createVNode(_component_van_field, {
            modelValue: _ctx.submitform.title,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.submitform.title) = $event)),
            rules: [{ required: true, message: '巡检名称必填' }],
            required: "",
            label: "巡检名称",
            placeholder: "请填写巡检名称",
            maxlength: "50",
            "input-align": "right"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_van_field, {
            modelValue: _ctx.areaName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.areaName) = $event)),
            "is-link": "",
            readonly: "",
            required: "",
            label: "所在区域",
            placeholder: "请选择所在区域",
            "input-align": "right",
            onClick: _ctx.fOpenAreaPicker,
            rules: [{ required: true, message: '请选择所在区域' }]
          }, null, 8, ["modelValue", "onClick"]),
          _createVNode(_component_van_popup, {
            show: _ctx.showAreaPicker,
            "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showAreaPicker) = $event)),
            position: "bottom",
            round: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_van_picker, {
                title: "所在区域",
                columns: _ctx.areaColumns,
                "columns-field-names": { text: 'name' },
                onConfirm: _ctx.onAreaConfirm,
                onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAreaPicker = false))
              }, null, 8, ["columns", "onConfirm"])
            ]),
            _: 1
          }, 8, ["show"]),
          _createVNode(_component_van_field, {
            modelValue: _ctx.dangerTypeIdname,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dangerTypeIdname) = $event)),
            "is-link": "",
            readonly: "",
            required: "",
            "input-align": "right",
            label: "安全隐患类型",
            placeholder: "请选择类型",
            onClick: _ctx.fOpenDangerTypePicker,
            rules: [{ required: true, message: '请选择类型' }]
          }, null, 8, ["modelValue", "onClick"]),
          _createVNode(_component_van_popup, {
            show: _ctx.showDangerTypePicker,
            "onUpdate:show": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showDangerTypePicker) = $event)),
            position: "bottom",
            round: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_van_picker, {
                columns: _ctx.dangerTypeColumns,
                title: "隐患类型",
                "columns-field-names": { text: 'name' },
                onConfirm: _ctx.onDangerConfirm,
                onCancel: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showDangerTypePicker = false))
              }, null, 8, ["columns", "onConfirm"])
            ]),
            _: 1
          }, 8, ["show"]),
          _createVNode(_component_van_field, {
            modelValue: _ctx.dangerSourceIdname,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dangerSourceIdname) = $event)),
            "is-link": "",
            readonly: "",
            required: "",
            label: "安全隐患位置",
            "input-align": "right",
            placeholder: "请选择",
            onClick: _ctx.fOpenDangerSourcePicker,
            rules: [{ required: true, message: '请选择隐患位置' }]
          }, null, 8, ["modelValue", "onClick"]),
          _createVNode(_component_van_popup, {
            show: _ctx.showDangerSourceIdPicker,
            "onUpdate:show": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showDangerSourceIdPicker) = $event)),
            position: "bottom",
            round: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_van_picker, {
                columns: _ctx.dangerSourceIdPickerColumns,
                title: "安全隐患位置",
                "columns-field-names": { text: 'name' },
                onConfirm: _ctx.onDangerSourceConfirm,
                onCancel: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showDangerSourceIdPicker = false))
              }, null, 8, ["columns", "onConfirm"])
            ]),
            _: 1
          }, 8, ["show"]),
          _createVNode(_component_van_field, {
            modelValue: _ctx.resultname,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.resultname) = $event)),
            rules: [{ required: true, message: '请选择巡检结果' }],
            "is-link": "",
            readonly: "",
            required: "",
            label: "巡检结果",
            "input-align": "right",
            placeholder: "请选择巡检结果",
            onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.resultPicker = true))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_van_popup, {
            show: _ctx.resultPicker,
            "onUpdate:show": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.resultPicker) = $event)),
            position: "bottom",
            round: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_van_picker, {
                columns: _ctx.resultPickerscolumns,
                title: "巡检结果",
                "columns-field-names": { text: 'name' },
                onConfirm: _ctx.onResultConfirm,
                onCancel: _cache[12] || (_cache[12] = ($event: any) => (_ctx.resultPicker = false))
              }, null, 8, ["columns", "onConfirm"])
            ]),
            _: 1
          }, 8, ["show"]),
          (_ctx.submitform.result == 2)
            ? (_openBlock(), _createBlock(_component_van_field, {
                key: 0,
                modelValue: _ctx.personliableName,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.personliableName) = $event)),
                "is-link": "",
                readonly: "",
                required: "",
                label: "负责人",
                "input-align": "right",
                placeholder: "请选择负责人",
                onClick: _ctx.fShowPersonliableDialog,
                rules: [{ required: true, message: '请选择负责人' }]
              }, null, 8, ["modelValue", "onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_van_field, {
            modelValue: _ctx.submitform.remark,
            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.submitform.remark) = $event)),
            label: "备注",
            type: "textarea",
            maxlength: "400",
            rows: "2",
            placeholder: "请填写备注",
            "show-word-limit": ""
          }, null, 8, ["modelValue"]),
          _createVNode(_component_van_field, {
            class: "fujian",
            disabled: "",
            label: "附件"
          }),
          _createVNode(_component_van_field, {
            name: "uploader",
            style: {"background":"transparent"}
          }, {
            input: _withCtx(() => [
              _createVNode(_component_van_uploader, {
                modelValue: _ctx.uploadFiles,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.uploadFiles) = $event)),
                "after-read": _ctx.afterRead,
                multiple: _ctx.$isIOS()
              }, null, 8, ["modelValue", "after-read", "multiple"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.bAllow)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "submit-btn",
                  onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.fSumbitForm && _ctx.fSumbitForm(...args)))
                }, "提交"))
              : _createCommentVNode("", true),
            (!_ctx.bAllow)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, "提交"))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _createVNode(_component_van_dialog, {
      show: _ctx.dangerDialog,
      "onUpdate:show": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.dangerDialog) = $event)),
      title: "新增安全隐患类型",
      "show-cancel-button": "",
      onConfirm: _ctx.fDangerSure,
      "before-close": _ctx.fDangerBeforeClose
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_form, {
          ref: "dangerform",
          "show-error-message": false,
          "show-error": true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_van_cell_group, null, {
              default: _withCtx(() => [
                _createVNode(_component_van_field, {
                  rules: [{ required: true, message: '隐患类型必填' }],
                  modelValue: _ctx.addDangerValue,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.addDangerValue) = $event)),
                  modelModifiers: { trim: true },
                  required: "",
                  clearable: "",
                  label: "隐患类型",
                  placeholder: "请输入隐患类型"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    }, 8, ["show", "onConfirm", "before-close"]),
    _createVNode(_component_van_dialog, {
      show: _ctx.dangerSourceDialog,
      "onUpdate:show": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.dangerSourceDialog) = $event)),
      title: "新增安全隐患位置",
      "show-cancel-button": "",
      onConfirm: _ctx.fDangerSourceSure,
      "before-close": _ctx.fDangerBeforeClose
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_form, {
          ref: "dangersourceform",
          "show-error-message": false,
          "show-error": true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_van_cell_group, null, {
              default: _withCtx(() => [
                _createVNode(_component_van_field, {
                  rules: [{ required: true, message: '位置名称必填' }],
                  modelValue: _ctx.dangersourcevalue,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.dangersourcevalue) = $event)),
                  required: "",
                  label: "位置名称",
                  placeholder: "请输入位置名称"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_van_field, {
              modelValue: _ctx.dangersourceremark,
              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.dangersourceremark) = $event)),
              clickable: "",
              clearable: "",
              label: "备注",
              placeholder: "请输入备注"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    }, 8, ["show", "onConfirm", "before-close"]),
    _createVNode(_component_personliable_dialog, { ref: "personliable" }, null, 512)
  ], 64))
}